<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        You obtained data during a kinetics experiment. If the reaction is second order in A:
      </p>

      <p class="mb-2">a) What would you graph to obtain a straight line?</p>

      <s-textarea v-model="inputs.studentAIResponseA" class="mb-2" outlined></s-textarea>

      <p class="mb-n3">b) What would the units of the rate constant be?</p>

      <v-radio-group
        v-model="inputs.studentUnitsResponse"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsUnits"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) The half-life is not constant. How is the time for the first halving of [A] (the first
        half life or 100% → 50%) related to the second halving of [A] (the second half-life or 50% →
        25%)?
      </p>

      <s-textarea v-model="inputs.studentAIResponseC" class="mb-n2" outlined></s-textarea>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question486',
  components: {
    STextarea,
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAIResponseA: null,
        studentUnitsResponse: null,
        studentAIResponseC: null,
      },
      optionsUnits: [
        {text: '$\\text{M}^2\\,\\text{s}^{-1}$', value: 'M2perS'},
        {text: '$\\text{M}\\,\\text{s}^{-1}$', value: 'MperS'},
        {text: '$\\text{s}^{-1}$', value: 'perS'},
        {text: '$\\text{M}^{-1}\\,\\text{s}^{-1}$', value: 'perMperS'},
        {text: '$\\text{M}^{-2}\\,\\text{s}^{-1}$', value: 'perM2perS'},
        {text: '$\\text{s}\\,\\text{M}^{-1}$', value: 'sPerM'},
      ],
    };
  },
};
</script>
